<template>
  <div class="container-fluid">
    <div class="row">
      <Survey />
    </div>
  </div>
</template>

<script>
import Survey from '@/components/survey/Survey';

export default {
  name: 'Test',
  components: {
    Survey,
  },
  data() {
    return {
      cookie: null
    }
  },
};
</script>
