import { createWebHistory } from 'vue-router';
import translations from '../lang/translations';
import localizedURLs from '../lang/localized-urls';
import { createLangRouter } from 'vue-lang-router';
import Home from '../views/Home.vue';
import MetaService from '../utils/services/MetaService';
import store from '../store';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/questions',
    name: 'Test',
    component: () => import(/* webpackChunkName: "test" */ '../views/Test.vue'),
  },
  {
    path: '/full-results/:id',
    name: 'UserResults',
    component: () => import(/* webpackChunkName: "user-results" */ '../views/UserResults.vue'),
  },
  {
    path: '/unsubscribe/:id',
    name: 'Unsubscribe',
    component: () => import(/* webpackChunkName: "unsubscribe" */ '../views/Unsubscribe.vue'),
  },
  {
    path: '/:catchAll(.*)*',
    redirect: { name: 'Home' },
  },
];

const langRouterOptions = {
  defaultLanguage: 'en',
  translations,
  localizedURLs,
};

const routerOptions = {
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 }
  },
};

const router = createLangRouter(langRouterOptions, routerOptions);

router.beforeEach((to, _from, next) => {
  const path = to.path;
  const lang = path.substring(1, 3);

  MetaService.setMeta(lang);

  if (to.name === 'Home') store.commit('setHomePage', true);
  else store.commit('setHomePage', false);

  next();
});

export default router;
