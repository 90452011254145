<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 results-col">
        <img
          class="img-fluid bg-img"
          src="@/assets/img/img-1-desk.jpg"
          alt="Question Images"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppBanner',
};
</script>
