<template>
  <div class="col-12 col-lg-10 error-card">
    <app-card>
      <template #title>{{ $t('bug.title') }}</template>
      <template #text>{{ $t('bug.sub') }}</template>
      <template #button>
        <app-button @clicked="refresh">
          {{ $t('bug.btn') }}
        </app-button>
        <div class="contact-link mt-2">
          <p class="mt-4 mb-0">{{ $t('bug.support') }}</p>
          <app-support-link>{{ $t('bug.restricted.link') }}</app-support-link>
        </div>
      </template>
    </app-card>
  </div>
</template>

<script>
export default {
  name: 'AppError',
  methods: {
    refresh() {
      window.location.reload();
    },
  },
};
</script>
