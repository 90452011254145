<template>
  <nav class="navbar shadow-sm">
    <div class="container">
      <localized-link class="navbar-brand" to="/">
        <NavBrand />
      </localized-link>
      <div class="spacer desktop-spacer"></div>
      <div class="nav-item">
        {{ $t('nav.brand') }}
      </div>
      <div class="spacer"></div>
      <LangSwitcher v-if="isHomePage"/>
      <div class="spacer" v-else></div>
    </div>
  </nav>
</template>

<script>
import NavBrand from '@/components/nav/NavBrand';
import LangSwitcher from '@/components/nav/LangSwitcher';

export default {
  name: 'Navbar',
  components: {
    NavBrand,
    LangSwitcher,
  },
  computed: {
    isHomePage() {
      return this.$store.state.users.homePage;
    }
  },
};
</script>

<style lang="scss">
.navbar {
  .container {
    padding: 0rem 0.5rem;
    .navbar-brand {
      margin-right: 0 !important;
    }
    .desktop-spacer {
      display: none;
    }
    .nav-item {
      display: none;
    }
  }
}

@include bp-up(md) {
  .navbar {
    .container {
      padding: 0rem 0rem;
      .desktop-spacer {
        display: block;
      }
      .nav-item {
        display: block;
      }
    }
  }
}
</style>
