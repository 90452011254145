const percentageNl = [
  {
    choice: 'Je koos voor belangrijk',
    value: 100,
  },
  {
    choice: 'Je koos voor neutraal',
    value: 50,
  },
  {
    choice: 'Je koos voor niet belangrijk',
    value: 0,
  },
];

const percentageFr = [
  {
    choice: 'Vous avez choisi important',
    value: 100,
  },
  {
    choice: 'Vous avez choisi neutre',
    value: 50,
  },
  {
    choice: 'Vous avez choisi pas important',
    value: 0,
  },
];

export { percentageNl, percentageFr };
