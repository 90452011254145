<template>
  <a class="cookie-link" @click="getCookies">
    {{ $t('cookies.manage.link') }}
  </a>
  <el-dialog v-model="showModal" custom-class="cookies-params">
    <img class="cookies-logo" src="@/assets/img/logo-desktop.svg" />
    <div class="cookies-content">
      <h1>{{ $t('cookies.manage.title1') }}</h1>
      <p class="pb-4">
        {{ $t('cookies.manage.sub') }}
      </p>
      <h1>{{ $t('cookies.manage.title2') }}</h1>
      <el-collapse>
        <el-collapse-item name="1">
          <template #title>
            <span class="cookie-title">{{
              $t('cookies.manage.cookies1')
            }}</span>
            <el-switch
              v-model="cookie.consent"
              style="margin-left: 30px"
              disabled
            >
            </el-switch>
          </template>
          <p>
            {{ $t('cookies.manage.description1') }}
          </p>
        </el-collapse-item>
        <el-collapse-item name="2">
          <template #title>
            <span class="cookie-title">{{
              $t('cookies.manage.cookies3')
            }}</span>
            <el-switch v-model="cookie.analytics" style="margin-left: 30px">
            </el-switch>
          </template>
          <p>
            {{ $t('cookies.manage.description3') }}
          </p>
        </el-collapse-item>
      </el-collapse>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <app-button @clicked="saveCookies">{{
          $t('cookies.manage.btn')
        }}</app-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import CookieService from '@/utils/services/CookieService';

export default {
  name: 'CookiesParams',
  emits: ['cookiesSet'],
  data() {
    return {
      showModal: false,
      cookie: {
        consent: true,
        date: new Date().toString(),
        analytics: false,
      },
    };
  },
  methods: {
    getCookies() {
      const appCookies = this.$cookies.get('app_cookies');
      this.cookie.consent = appCookies?.consent || true;
      this.cookie.analytics = appCookies?.analytics;
      this.showModal = true;
    },
    removeAnalytics() {
      this.$matomo.forgetCookieConsentGiven();
      this.$matomo.deleteCookies();
    },
    setAnalytics() {
      const analytics = this.$matomo.getRememberedCookieConsent();
      if (!analytics) this.$matomo.rememberCookieConsentGiven();
    },
    saveCookies() {
      if (!this.cookie.analytics) this.removeAnalytics();
      else this.setAnalytics();

      this.$cookies.set('app_cookies', JSON.stringify(this.cookie), '30d');

      CookieService.getSession(this.cookie).then(() => {
        this.$emit('cookiesSet');
        this.showModal = false;
      });
    },
  },
};
</script>

<style lang="scss">
.el-dialog.cookies-params {
  width: 50% !important;
}

@include bp-down(lg) {
  .el-dialog.cookies-params {
    width: 80% !important;
  }
}
</style>
