<template>
  <button class="btn" :class="mode" @click="btnClicked" :disabled="disabled">
    <slot></slot>
  </button>
</template>

<script>
export default {
  props: ['mode', 'disabled'],
  emits: ['clicked'],
  methods: {
    btnClicked() {
      this.$emit('clicked');
    },
  },
};
</script>

<style lang="scss">
.btn {
  background-color: $dark-bg;
  border-color: $dark-bg;
  border-radius: 4px;
  padding: 10px 25px;
  text-align: center;
  font-family: $default-font;
  font-size: 18px;
  color: $light;
  opacity: 1;
  &:hover {
    background-color: $info;
    border-color: $info;
    color: $light;
  }
  a {
    color: $light;
    text-decoration: none;
  }
}
</style>
