<template>
  <app-banner />
  <div class="container-fluid">
    <div class="row user-results">
      <div class="col-12 text-center mt-4">
        <h1 class="mb-4 app-title mt-4">{{ $t('results.title') }}</h1>
        <p class="results-sub">{{ $t('results.sub') }}</p>
      </div>
    </div>
    <full-results />
  </div>
</template>

<script>
import FullResults from '@/components/results/FullResults';

export default {
  name: 'UserResults',
  components: {
    FullResults,
  },
};
</script>

<style lang="scss">
.user-results {
  padding-right: 2rem !important;
  padding-left: 2rem !important;
  .results-sub {
    max-width: 800px;
    margin: 0 auto;
    padding-bottom: 1rem;
  }
}
</style>
