<template>
  <div class="col-12 col-xl-6 img-col survey-col">
    <img
      class="img-fluid bg-img mobile-img"
      :src="require(`@/assets/img/img-${current}-desk.jpg`)"
      alt="Question Images"
    />
    <img
      class="img-fluid bg-img desktop-img"
      :src="require(`@/assets/img/img-${current}-desk.jpg`)"
      alt="Question Images"
    />
  </div>
  <div class="col-12 col-xl-6 mb-4">
    <div class="progress-container">
      <div class="progress">
        <div
          class="progress-bar"
          role="progressbar"
          :style="`width: ${progress}%`"
          :aria-valuenow="progress"
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
      </div>
      <p>
        <span class="text-bold">{{ progress }}%</span>
        {{ $t('survey.progress') }}
      </p>
    </div>
    <template v-for="(item, index) in survey" :key="index">
      <div
        class="container survey-container"
        id="survey-container"
        v-if="current === index + 1"
      >
        <survey-item
          :item="item"
          :index="index"
          @next="getNext"
          @previous="getPrevious"
          @showEnv="showPercentage"
          @createUser="createUser"
        />
      </div>
    </template>
  </div>
</template>

<script>
import SurveyService from '@/utils/services/SurveyService';
import { surveyFr, surveyNl } from '@/utils/data/survey';
import SurveyItem from '@/components/survey/SurveyItem';
import UserService from '@/utils/services/UserService';

export default {
  name: 'Survey',
  components: {
    SurveyItem,
  },
  data() {
    return {
      answers: {},
      current: 1,
      progress: 0,
      env_percentage: '',
      lang: 'nl',
      user: {},
    };
  },
  computed: {
    survey() {
      return this.lang === 'fr' ? surveyFr : surveyNl;
    },
  },
  created() {
    this.lang = this.$i18n.locale;
  },
  methods: {
    async handleSubmit() {
      const resultsId = await SurveyService.submitAnswers(
        this.lang,
        this.answers
      );

      await UserService.createUser({ ...this.user, results_id: resultsId });

      this.$router.push({ name: 'UserResults', params: { id: resultsId } });
    },
    getNext(value, key) {
      this.trackMatomoEvent(this.current);

      this.progress = SurveyService.getProgress(
        this.current,
        this.survey.length
      );

      this.answers[key] = value;

      if (this.current === this.survey.length) return this.handleSubmit();

      if (key === 'charge' && value === 0) {
        this.answers.station = 0;
        this.current += 2;
      } else this.current += 1;

      document.getElementById('survey-container').scrollTop = 0;
    },
    getPrevious(index, key) {
      let progress;

      if (key === 'zip' && this.answers.charge === 0) {
        this.current = index - 1;
        progress = index - 2;
      } else if (index === 0) {
        progress = 0;
      } else {
        this.current = index;
        progress = index - 1;
      }

      this.progress = SurveyService.getProgress(progress, this.survey.length);
      document.getElementById('survey-container').scrollTop = 0;
    },
    showPercentage(choice) {
      this.env_percentage = choice;
    },
    createUser(user) {
      this.user = {
        ...user,
        zip: this.answers.zip,
        lang: this.lang,
      };
    },
    trackMatomoEvent(questionIndex) {
      return this.$matomo.trackEvent(
        'question',
        questionIndex
      );
    },
  },
};
</script>

<style lang="scss">
.survey-container {
  margin-top: 1rem;
}
.desktop-img {
  display: none;
}
.percentage-row {
  display: flex;
  justify-content: center;
  max-width: 90%;
  margin: 0 auto;
  .percentage-wrapper {
    display: flex;
    justify-content: center;
    max-width: fit-content;
    padding: 0.75rem 1rem;
    border-radius: 4px;
    background-color: $lightest-green;
    border: 1px solid $secondary;
    text-align: center;
    p {
      margin: 0;
      font-size: 14px;
    }
  }
}

.progress-container {
  margin-top: 2rem;
  .progress {
    height: 0.75rem !important;
    max-width: 85%;
    margin: 0 auto;
    background-color: $light-green;
    .progress-bar {
      background-color: $secondary !important;
    }
  }
  p {
    font-size: 14px;
    color: $dark-green;
    max-width: 75%;
    margin: 0 auto;
    padding-left: 2rem;
  }
}

@include bp-up(md) {
  .desktop-img {
    display: flex;
  }
  .mobile-img {
    display: none;
  }
  .progress-container {
    .progress {
      max-width: 70%;
    }
  }
}

@include bp-between(md, lg) {
  .progress-container {
    margin-top: 0;
  }
}
</style>
