const enginesFr = [
  {
    name: 'diesel',
    title: 'Le diesel:',
    sub: 'le marathonien',
    convenience: 80,
    description:
      "Une voiture diesel possède un moteur à combustion et convient pour les longues distances. Vous pouvez trouver du diesel dans n'importe quelle station-service et pour presque tous les modèles de voitures. La plupart des nouvelles voitures diesel font également le plein d'AdBlue, qui élimine les substances nocives (telles que les oxydes d'azote) des gaz d'échappement.",
    warning:
      'Attention : les voitures roulant au diesel sont de moins en moins conseillées en raison de leurs émissions nocives. L’Union Européenne a voté une loi pour interdire la vente de véhicules essence et diesel à partir de 2035.',
    env: [
      "Le diesel est le carburant le moins durable, émettant du CO2, des oxydes d'azote (NOx) et des particules. Grâce à l'AdBlue présent dans la plupart des voitures diesel modernes, les oxydes d'azote sont décomposés en azote inoffensif et en vapeur d'eau. Cependant, les émissions de CO2 restent les mêmes.",
    ],
    eco1: "On trouve des voitures diesel dans la plupart des catégories de prix des voitures neuves. Pour les petites voitures diesel, en revanche, l'offre est en baisse. Les moteurs diesel modernes consomment moins et répondent à des normes d'émission plus strictes (par exemple, Euro 6).  Ce sont ces normes qui déterminent si votre voiture peut rouler dans une zone à faibles émissions (LEZ) ou non. Une voiture diesel consomme également en moyenne 15% de carburant en moins qu'une voiture à essence similaire.",
    eco2: "En plus de votre achat, vous aurez des coûts récurrents tels que l'entretien, le carburant et les taxes. Pour avoir une idée du budget de consommation des voitures diesel, appliquez cette règle empirique (calculée sur 9,5 années d'utilisation de la voiture):",
    eco_list: [
      "Voitures diesel dont le prix d'achat est d'environ 25 000 € : plus de 80% du prix d'achat comme budget de consommation.",
      "Le budget de consommation diminue de manière inversement proportionnelle au prix d'achat. Ainsi, pourune voiture diesel d'environ 60 000 € : 40 à 50% du prix d'achat comme budget de consommation.",
    ],
    conv1:
      "Si vous conduisez une voiture diesel, vous pouvez compter sur un vaste réseau de stations-service en Belgique et à l'étranger. Vous disposez également de la plus grande autonomie avec une voiture diesel. Certains modèles peuvent parcourir jusqu'à 1 200 km avec un seul plein. Le ravitaillement en carburant se fait de la manière habituelle : à la pompe avec un pistolet de ravitaillement. L'AdBlue est également rechargé facilement et proprement à la pompe de DATS 24.",
    conv2: "Comment faire le plein d'AdBlue ?",
    conv_video: 'https://www.youtube.com/embed/vgcOr9stgxA',
    bonus_list: [
      {
        text: "Vous pouvez utiliser votre budget d'achat de manière flexible. Vous avez un large choix de marques et de modèles.",
        type: 'list',
      },
      {
        text: "Vous économisez au moins 10 € par an en faisant le plein d'AdBlue à la pompe plutôt qu'avec un jerrican. Par litre, vous économisez un peu plus de 1 € à la pompe, sachant que vous remplissez votre AdBlue au moins deux fois par an avec au moins 5 litres.",
        type: 'list',
      },
      {
        text: 'vérifiez chaque mois la pression de vos pneus. De cette façon, vous pouvez économiser 2 pleins par an.',
        type: 'tip',
      },
      {
        text: 'gardez votre voiture (y compris le coffre) aussi vide que possible. Pour chaque 100 kg que vous ne transportez pas, vous économisez 7% de carburant.',
        type: 'tip',
      },
    ],
    extra_list: [
      {
        type: 'list',
        text: "N'oubliez pas de vérifier quelle est la norme européenne de la voiture. Les nouvelles voitures diesel respectent presque toujours la norme EURO6, afin de pouvoir entrer sans problème dans les zones à faibles émissions (LEZ).",
      },
      {
        type: 'tip',
        text: "certaines options influencent la consommation d'une voiture. Pensez, par exemple, à un toit en verre panoramique (plus de poids) et à une lunette arrière, des sièges et des rétroviseurs latéraux chauffants (consommation d'électricité). Cela peut coûter un demi-litre de carburant en plus par 100 km. ",
      },
      {
        type: 'inline-link',
        text: 'Trouvez toutes ',
        text2: 'les stations-service DATS\u00a024',
        text3: ' avec AdBlue sur votre itinéraire.',
        text4: '',
        url: 'https://customer.dats24.be/wps/portal/datscustomer/fr/b2c/locator?featureId=58',
      },
    ],
  },
  {
    name: 'petrol',
    title: "L'essence:",
    sub: 'le classique',
    convenience: 80,
    description:
      "Une voiture à essence est équipée d'un moteur à combustion interne et est particulièrement adaptée aux courtes distances. Vous pouvez trouver de l'essence partout et pour chaque modèle de voiture. Si vous recherchez spécifiquement une petite voiture, vous avez plus de chances de trouver ce que vous cherchez dans les voitures à essence que dans les voitures diesel, où l'offre est sensiblement réduite.",
    warning:
      'Attention : les voitures roulant à l’essence sont de moins en moins conseillées en raison de leurs émissions nocives. L’Union Européenne a voté une loi pour interdire la vente de véhicules essence et diesel à partir de 2035.',
    env: [
      "Après le diesel (et le GPL), l'essence est le carburant le moins propre, avec des émissions élevées de CO2, d'oxydes d'azote (NOx) et de particules.",
    ],
    eco1: "Vous trouverez des voitures à essence dans toutes les catégories de prix des voitures neuves. Vous avez donc un large choix de marques, de modèles et de finitions. Les voitures à essence sont celles qui consomment le plus de carburant par litre (ou par kilogramme) de tous les systèmes de propulsion existants aujourd'hui.",
    eco2: "En plus de votre achat, vous aurez des coûts récurrents tels que l'entretien, le carburant et les taxes. Pour avoir une idée du budget de consommation des voitures à essence, appliquez cette règle empirique (calculée sur 9,5 années d'utilisation de la voiture) :",
    eco_list: [
      "Voitures à essence d'un prix d'achat d'environ 25 000 € : environ 110% du prix d'achat comme budget de consommation.",
      "Le budget de consommation diminue de manière inversement proportionnelle au prix d'achat. Voitures à essence autour de 60 000 € : environ 70% du prix d'achat comme budget de consommation.",
    ],
    conv1:
      "L'essence est le classique pour une raison. Les premières \"voitures\" à moteur à combustion fonctionnaient à l'essence. Et le mode de ravitaillement est celui que nous connaissons tous aujourd'hui. Vous pouvez compter sur un large réseau de stations-service en Belgique et à l'étranger.",
    conv2: "Faire le plein d'essence dans votre région",
    conv_url:
      'https://customer.dats24.be/wps/portal/datscustomer/fr/b2c/locator',
    bonus_list: [
      {
        type: 'list',
        text: "Vous pouvez utiliser votre budget d'achat de manière flexible. Vous avez un large choix de marques et de modèles. ",
      },
      {
        type: 'list',
        text: "Vérifiez sur le certificat de conformité ou dans la trappe à carburant quelle essence est recommandée pour la voiture. Si l'E10 (Euro95) est suffisant, vous n'avez certainement pas besoin de faire le plein avec l'E5 (Euro98). Sur un réservoir de 50 litres, cela vous permettra d'économiser rapidement près de 10% de votre budget.",
      },
      {
        type: 'tip',
        text: 'vérifiez chaque mois la pression de vos pneus. De cette façon, vous pouvez économiser 2 arrêts de carburant par an.',
      },
      {
        type: 'tip',
        text: 'gardez votre voiture (y compris le coffre) aussi vide que possible. Pour chaque tranche de 100 kg que vous ne transportez pas, vous économisez 7% de carburant.',
      },
    ],
    extra_list: [
      {
        type: 'tip',
        text: "certaines options ont un impact sur la consommation d'une voiture. Pensez, par exemple, à un coffre de toit ou au type de pneus (les pneus à profil bas ou les pneus larges provoquent plus de friction) et aux éléments chauffants tels que la lunette arrière, les sièges et les rétroviseurs latéraux (consommation d'électricité). Cela peut coûter un demi-litre de carburant en plus par 100 km.",
      },
      {
        type: 'inline-link',
        text: 'Trouvez toutes ',
        text2: 'les stations-service DATS\u00a024',
        text3: ' sur votre itinéraire.',
        text4: '',
        url: 'https://customer.dats24.be/wps/portal/datscustomer/fr/b2c/locator',
      },
    ],
  },
  {
    name: 'cng',
    title: 'Le CNG:',
    sub: 'le carburant alternatif',
    convenience: 80,
    description:
      "Le moteur à combustion de cette voiture fonctionne au CNG et à l'essence. Les deux peuvent être ravitaillés en carburant au fur et à mesure des besoins. Les émissions de CO2 du CNG sont jusqu'à 10% inférieures à celles du diesel et de l'essence, et les émissions d'oxyde d'azote (NOx) jusqu'à 90% inférieures. Les émissions de particules sont réduites jusqu'à 7%.",
    env: [
      "Le CNG émet moins de CO2 que le diesel et l'essence, et beaucoup moins d'oxydes d'azote (NOx) et de particules. La condition est que vous laissiez la voiture fonctionner au CNG et non sur le réservoir d'essence.",
    ],
    eco1: "Le prix d'achat d'un véhicule CNG se situe entre celui d'un véhicule à essence et celui d'un véhicule diesel. Vous pouvez trouver différents modèles et catégories de prix dans les grandes marques automobiles européennes (Audi, Seat et Volkswagen). L'offre s'est toutefois réduite ces dernières années. Bon à savoir : 1 kg de CNG vous permettra d'aller plus loin qu'un litre de diesel.",
    eco2: "En plus de votre achat, vous aurez des coûts récurrents tels que l'entretien, le carburant et les taxes. Pour avoir une idée du budget de consommation des voitures CNG, appliquez cette règle empirique (calculée sur 9,5 années d'utilisation de la voiture):",
    eco_list: [
      "Voitures CNG dont le prix d'achat ne dépasse pas 25 000 €: plus de 80% du prix d'achat comme budget de consommation.",
      "Le budget de consommation diminue de manière inversement proportionnelle au prix d'achat. Une voiture CNG dont le prix d'achat est olus de 40.000 € : environ 60% du prix d'achat comme budget de consommation.",
    ],
    conv1:
      "Le CNG convient parfaitement à ceux qui veulent conduire de manière plus durable et conserver leurs habitudes de ravitaillement. Le CNG est plus durable que le diesel et l'essence et tout aussi fiable dans la manière de faire le plein et de conduire. En Belgique, il existe un vaste réseau de stations de remplissage de CNG, dont la plupart sont situées à DATS 24. Vous trouverez également des stations-service CNG dans la plupart des pays voisins. En Italie, surtout, vous pouvez compter sur un vaste réseau.",
    conv2: 'Comment faire le plein de CNG ? ',
    conv_video: 'https://www.youtube.com/embed/oRtPavEHO9A',
    conv4: 'Faire le plein de CNG dans votre région',
    conv_url2:
      'https://customer.dats24.be/wps/portal/datscustomer/fr/b2c/locator?featureId=13',
    bonus_list: [
      {
        type: 'list',
        text: 'Par rapport au diesel,vous économisez 7% de CO2, 7% de particules et 90% de protoxydes d’azote.',
      },
      {
        type: 'list',
        text: "Depuis un certain temps, le gestionnaire de réseau Fluvius est occupé à passer du gaz à faible pouvoir calorifique au gaz à fort pouvoir calorifique (plus riche en énergie). Vous trouverez donc également du CNG à haut pouvoir calorifique dans de plus en plus de stations-service. Vous le payez un peu plus cher, mais il ira aussi plus loin, car le CNG riche en calories contient plus d'énergie. Utilisez l'outil de recherche de stations-service DATS 24 pour savoir quel type de CNG est disponible sur votre itinéraire. ",
      },
      {
        type: 'tip',
        text: "gardez une vitesse constante autant que possible. Vous avez besoin de ralentir ? Lâchez juste l'accélérateur. Cela permettra de réduire votre consommation de carburant.",
      },
      {
        type: 'tip',
        text: "à l'inverse, vous pouvez aussi utiliser la vitesse accumulée de la voiture autant que possible. Vous descendez ? Il peut suffire de lever le pied de l'accélérateur et de laisser la voiture descendre la pente. Et donc vous utilisez moins de carburant.",
      },
    ],
    extra_list: [
      {
        type: 'list',
        text: 'Vérifiez quels constructeurs proposent des véhicules CNG et si vous pouvez trouver parmi eux le modèle qui vous convient.',
      },
      {
        type: 'inline-link',
        text: 'Trouvez toutes ',
        text2: 'les stations-service DATS\u00a024',
        text3: ' avec CNG sur votre itinéraire.',
        text4: '',
        url: 'https://customer.dats24.be/wps/portal/datscustomer/fr/b2c/locator?featureId=13',
      },
    ],
  },
  {
    name: 'hev',
    title: "L'hybride:",
    sub: "l'urbain",
    convenience: 50,
    description:
      "Un véhicule hybride (HEV) fonctionne avec un moteur à combustion et un moteur électrique, sans prise. D'ailleurs, les hybrides sont toujours automatiques, comme toutes les voitures électriques. Il est à son meilleur sur de courtes distances. Il se recharge tout seul pendant que vous conduisez. Cette voiture a besoin d'être ravitaillée. En mode électrique, elle n'émet aucune émission directe.",
    env: [
      "L'énergie libérée lors du freinage est en partie récupérée dans la batterie, qui alimente le moteur électrique. La consommation d'une voiture hybride est donc plus favorable que celle d'une voiture fonctionnant à l'essence ou au diesel. Le poids supplémentaire du moteur électrique et de la batterie augmente cependant la consommation de carburant. La clé est de conduire aussi consciemment que possible. Découvrez quelques conseils d'éco-conduite dans les points bonus.",
    ],
    eco1: "Une voiture hybride est légèrement plus chère qu'une voiture fonctionnant à l'essence ou au diesel. Vous pouvez les trouver dans plusieurs marques de voitures et dans un grand nombre de plusieurs modèles. Lorsque vous faites le plein, vous ne devez prendre en compte que le coût de l'essence ou du diesel. Les kilomètres que vous parcourez à l'électricité sont autoproduits et donc gratuits.",
    eco2: "En plus de votre achat, vous aurez des coûts récurrents comme l'entretien, le carburant et les taxes. Pour avoir une idée du budget de consommation des hybrides, appliquez cette règle empirique (calculée sur 9,5 années d'utilisation de la voiture):",
    eco_list: [
      "Voitures hybrides d'environ 25 000 € à l'achat : environ 80% du prix d'achat comme budget de consommation.",
      "Le budget de consommation diminue de manière inversement proportionnelle au prix d'achat. Une voiture hybride de plus de 60 000 euros : environ 45% du prix d'achat comme budget de consommation.",
    ],
    conv1:
      "Un hybride se recharge pendant que vous conduisez, par exemple lorsque vous freinez. Cela se produit automatiquement et vous n'en remarquez rien pendant la conduite. Il suffit de faire le plein d'essence pour le moteur à combustion. Si vous conduisez lentement et économiquement, vous tirerez le meilleur parti de cette voiture. Et vos habitudes de ravitaillement ne changeront pas du tout. ",
    conv2: 'Faire le plein d’essence dans votre région',
    conv_url:
      'https://customer.dats24.be/wps/portal/datscustomer/fr/b2c/locator',
    bonus_list: [
      {
        type: 'list',
        text: "Bien que les hybrides soient un peu plus chers que les voitures traditionnelles, vous pouvez être raisonnablement flexible avec votre budget d'achat. Vous avez un large choix de marques et de modèles. ",
      },
      {
        type: 'tip',
        text: 'gardez votre voiture (y compris le coffre) aussi vide que possible. Pour chaque tranche de 100 kg que vous ne transportez pas, vous économisez 7% de carburant.',
      },
      {
        type: 'tip',
        text: 'ne démarrez votre voiture que lorsque vous et vos passagers êtes prêts à partir. Ainsi, votre voiture ne consommera pas de carburant au ralenti.',
      },
    ],
    extra_list: [
      {
        type: 'list',
        text: "Vérifiez auprès de votre concessionnaire le type de carburant utilisé par l'hybride : essence ou diesel ? La plupart des hybrides fonctionnent à l'essence, mais il y a des exceptions. Le diesel est généralement légèrement plus cher que l'essence E10 (EURO 95).",
      },
      {
        type: 'list',
        text: "Vérifiez auprès de votre concessionnaire le nombre de kilomètres que la batterie peut fournir en électricité. D'un modèle à l'autre, il existe une différence dans l'autonomie électrique due à la taille de la batterie, au poids exact de la voiture, etc. ",
      },
      {
        type: 'inline-link',
        text: 'Trouvez toutes ',
        text2: 'les stations-service DATS\u00a024',
        text3: ' sur votre route.',
        text4: '',
        url: 'https://customer.dats24.be/wps/portal/datscustomer/fr/b2c/locator',
      },
    ],
  },
  {
    name: 'phev',
    title: "L'hybride rechargeable:",
    sub: 'le champion de la combinaison',
    convenience: 50,
    description:
      "Un véhicule hybride rechargeable (PHEV) fonctionne avec un moteur électrique, alimenté par une batterie, et un moteur à combustion interne. Cette voiture peut être ravitaillée et rechargée avec une prise. Tant qu'il roule à l'électricité, il n'émet aucune émission.",
    warning:
      'La voiture doit-elle pouvoir tracter une caravane ou une remorque ? Vérifiez auprès de votre revendeur si votre modèle peut le faire.',
    env: [
      "Conduire un véhicule hybride rechargeable signifie que vous n'émettez pas de CO2 directe localement lorsque vous roulez à l'électricité. Si vous rechargez votre véhicule avec de l’électricité 100% renouvelable dès que possible, vous tirez vraiment le meilleur parti de cette voiture en termes de climat et d'environnement. En revanche, il est vrai que votre consommation augmente fortement lorsque vous roulez avec un moteur à combustion. Avec un moteur électrique complètement chargé, une voiture hybride rechargeable peut parcourir entre 40 et 100 km à l'électricité. Pour beaucoup de gens, c'est suffisant pour les déplacements quotidiens.",
      "Vous pouvez donc choisir d'effectuer vos trajets quotidiens en mode électrique et de n'utiliser le moteur à combustion que pour les longues distances. L'écoconduite joue un rôle majeur à cet égard. Découvrez quelques conseils d'écoconduite dans les points bonus. ",
    ],
    eco1: "Une voiture hybride rechargeable est plus chère qu'une voiture fonctionnant à l'essence ou au diesel. De plus en plus de marques proposent des voitures hybrides rechargeables. En ce qui concerne le carburant, vous payez à la fois l'essence et l’électricité que vous consommez. À condition de rouler le plus possible en mode électrique, le coût à l’utilisation est inférieur à celui des voitures à essence. Vous évitez de parcourir de nombreux kilomètres avec de l'essence et la recharge électrique est moins chère que le plein d'essence.",
    eco2: "En plus de votre achat, vous aurez des coûts récurrents comme l'entretien, le carburant et les taxes. Pour avoir une idée du budget de consommation des hybrides, appliquez cette règle empirique (calculée sur 9,5 années d'utilisation de la voiture) :",
    eco_list: [
      "Voitures hybrides rechargeables, quel que soit le prix d'achat : 20 à 40% du prix d'achat comme budget de consommation. La différence de pourcentage dépend du nombre de kilomètres parcourus en mode électrique).",
    ],
    conv1:
      "Vous savez déjà comment faire le plein d'une voiture hybride rechargeable. La recharge électrique de la voiture peut encore être une nouveauté. La conduite d'un véhicule hybride rechargeable peut également nécessiter un certain temps d'adaptation, car sur certains modèles, vous utilisez la même pédale pour accélérer et freiner (lorsque vous relâchez la pédale). Vous devrez principalement charger un hybride rechargeable à la maison, et le recharger sur le chemin ou au travail. Pour cela, il est utile de se renseigner sur les stations de recharge qui se trouvent sur votre itinéraire.",
    conv2: 'Faire le plein d’essence dans votre région',
    conv_url:
      'https://customer.dats24.be/wps/portal/datscustomer/fr/b2c/locator',
    conv4: 'La recharge électrique dans votre région',
    conv_url2: 'https://msp.dats24.be/m/#/charging/stations?start',
    bonus_list: [
      {
        type: 'list',
        text: 'Vous avez un large choix de marques et de modèles. ',
      },
      {
        type: 'list',
        text: "Rechargez autant que possible : outre un prix au kilomètre plus bas, il n'y a pas d'émissions locales. En outre, si vous achetez de l'électricité 100% verte, la source d'énergie elle-même est également durable.",
      },
      {
        type: 'tip',
        text: "Adaptez votre style de conduite pour maximiser votre autonomie électrique. Conduisez lentement. N'utilisez la climatisation, le chauffage et le chauffage des fenêtres que lorsque cela est vraiment nécessaire. ",
      },
      {
        type: 'tip',
        text: "utilisez intelligemment votre ordinateur de bord. En surveillant votre consommation actuelle pendant la conduite, vous apprenez à adapter votre style de conduite et votre consommation diminuera. C'est particulièrement pratique avec les véhicules hybrides rechargeables, pour lesquels vous préférez rouler en mode électrique autant que possible.",
      },
      {
        type: 'inline-link',
        text: 'Consultez nos autres ',
        text2: "conseils d'économie",
        text3: ' pour les voitures électriques,',
        text4: 'car le froid hivernal, par exemple, réduira votre autonomie.',
        url: 'https://customer.dats24.be/wps/portal/datscustomer/fr/dats24/mobility/wintercheck',
      },
    ],
    extra_list: [
      {
        type: 'list',
        text: "Vérifiez auprès de votre concessionnaire si l'autonomie électrique couvre vos kilomètres quotidiens (pour aller au travail, à l'école, au magasin, etc.).",
      },
      {
        type: 'inline-link',
        text: 'Découvrez ',
        text2: 'la carte de paiement ',
        text3: 'qui vous convient le mieux.',
        text4: 'Posez-vous deux questions :',
        url: 'https://customer.dats24.be/wps/portal/datscustomer/fr/dats24/mobility/stappen-naar-elektrisch-rijden',
      },
      {
        type: 'num',
        text: '1.	Où vais-je me recharger le plus souvent lorsque je suis sur la route ?',
      },
      {
        type: 'num',
        text: "2.	Puis-je également activer des points de charge d'autres fournisseurs avec la carte ? La carte de recharge DATS 24 peut activer 9 des 10 points de recharge en Belgique et un large réseau en Europe.",
      },
      {
        type: 'inline-link',
        text: 'Trouvez toutes ',
        text2: 'les bornes de recharge',
        text3:
          ' où vous pouvez recharger avec la carte de recharge DATS\u00a024.',
        text4: '',
        url: 'https://msp.dats24.be/m/#/charging/stations?start',
      },
      {
        type: 'list',
        text: "Avez-vous un magasin habituel de Colruyt, OKay ou BioPlanet\u00a0? Ou est-ce que tu vas parfois à DreamLand\u00a0? La prochaine fois, vérifiez s'il existe déjà un point de charge DATS 24, car nous en ajoutons de plus en plus.",
      },
    ],
  },
  {
    name: 'bev',
    title: 'Le 100 % électrique:',
    sub: "toute la puissance de l'électricité",
    convenience: 20,
    description:
      "Une voiture électrique à batterie (BEV) est 100 % électrique. Elle est équipée d'un moteur électrique alimenté par une grande batterie (de plus de 100 kWh) au lieu d'un moteur à combustion interne. Vous chargez cette voiture à une borne de recharge. Elle ne produit aucune émission directe localement.",
    warning:
      'La voiture doit-elle pouvoir tracter une caravane ou une remorque ? Vérifiez auprès de votre revendeur si votre modèle peut le faire.',
    env: [
      "Avec l'hydrogène, la batterie électrique est aujourd'hui le mode de conduite le plus propre qui existe, sans émissions de gaz d'échappement. Si vous chargez également la voiture avec de l'électricité 100% verte, vous bénéficiez d'un avantage maximal pour le climat et l'environnement.",
    ],
    eco1: "Les voitures électriques à batterie sont encore chères à l'achat aujourd'hui. Toutefois, le coût du carburant est moins élevé que celui d'une voiture à essence sur l’ensemble de sa durée de vie (9,5 ans). En outre, si vous faites le plein de votre voiture à la maison avec de l'électricité autoproduite (par exemple via des panneaux solaires), la recharge est plus écologique et plus économique. En tant que professionnel, vous pouvez également utiliser cette voiture à 100% pour des raisons fiscales, précisément parce qu'elle n'émet pas de gaz d'échappement.",
    eco2: "En plus de votre achat, vous aurez des coûts récurrents tels que l'entretien, le carburant et les taxes. Pour avoir une idée du budget de consommation des voitures électriques à batterie, appliquez cette règle empirique (calculée sur 9,5 ans d'utilisation de la voiture) :",
    eco_list: [
      "Voitures électriques à batterie dont le prix d'achat est d'environ 25 000 € : environ 30 % du prix d'achat comme budget de consommation.",
      "Le budget de consommation diminue de manière inversement proportionnelle au prix d'achat. Une voiture électrique à batterie de plus de 60 000 € : environ 10 % du prix d'achat comme budget de consommation.",
    ],
    conv1:
      "La conduite entièrement électrique exige un ajustement plus important de vos habitudes que les autres carburants. Il faut s'habituer à la conduite car avec cette voiture, vous accélérez et freinez avec la même pédale (lorsque vous lâchez la pédale). Il est également préférable de se préparer à de longs trajets en termes de points de recharge et d'autonomie. En particulier pour ces voitures, un style de conduite économique est la clé pour rouler le plus longtemps possible avec une seule charge. Découvrez quelques conseils d'écoconduite dans les points bonus. En Belgique, votre autonomie est déjà plus que suffisante pour vous déplacer pendant la journée et recharger à la maison le soir.",
    conv3:
      "Entre-temps, le réseau de points de charge en Belgique et dans les pays voisins s'étend. De plus en plus d'options de recharge sont également disponibles pour un usage domestique, à condition que vous disposiez de l'espace nécessaire pour installer une borne de recharge. ",
    conv2: 'La recharge électrique dans votre région',
    conv_url: 'https://msp.dats24.be/m/#/charging/stations?start',
    bonus_list: [
      {
        type: 'list',
        text: 'Avec une voiture électrique à batterie, vous ménagez surtout l’environnement et le climat. ',
      },
      {
        type: 'tip',
        text: "adaptez votre style de conduite pour maximiser votre autonomie électrique. Conduisez lentement. N'utilisez la climatisation, le chauffage, les sièges et les vitres que lorsque cela est vraiment nécessaire.",
      },
      {
        type: 'tip',
        text: "utilisez le régulateur de vitesse aussi souvent que possible. Votre voiture maintiendra une vitesse constante, ce qui réduira considérablement votre consommation. Il s'agit d'une méthode pratique, surtout pour les voitures électriques, car elle vous permet de tirer le meilleur parti de votre autonomie.",
      },
      {
        type: 'inline-link',
        text: 'Consultez nos autres ',
        text2: "conseils d'économie",
        text3: ' pour les voitures électriques,',
        text4: 'car le froid hivernal, par exemple, réduira votre autonomie.',
        url: 'https://customer.dats24.be/wps/portal/datscustomer/fr/dats24/mobility/wintercheck',
      },
    ],
    extra_list: [
      {
        type: 'list',
        text: 'Vérifiez auprès de votre concessionnaire si l’autonomie électrique couvre vos kilomètres quotidiens (pour aller au travail, à l’école, au magasin, etc.).',
      },
      {
        type: 'inline-link',
        text: 'Découvrez ',
        text2: 'la carte de paiement ',
        text3: 'qui vous convient le mieux.',
        text4: 'Posez-vous deux questions :',
        url: 'https://customer.dats24.be/wps/portal/datscustomer/fr/dats24/mobility/stappen-naar-elektrisch-rijden',
      },
      {
        type: 'num',
        text: '1.	Où vais-je me recharger le plus souvent lorsque je suis sur la route ?',
      },
      {
        type: 'num',
        text: "2.	Puis-je également activer des points de charge d'autres fournisseurs avec la carte ? La carte de recharge DATS\u00a024 est utilisable chez 9 des 10 stations de recharge en Belgique et chez un large réseau en Europe.",
      },
      {
        type: 'inline-link',
        text: 'Trouvez toutes ',
        text2: 'les bornes de recharge',
        text3:
          ' où vous pouvez recharger avec la carte de recharge DATS\u00a024.',
        text4: '',
        url: 'https://msp.dats24.be/m/#/charging/stations?start',
      },
      {
        type: 'list',
        text: 'Vous allez régulièrement faire vos courses dans un magasin Colruyt, OKay ou Bio-Planet\u00a0? Ou vous vous rendez de temps en temps chez DreamLand\u00a0? La prochaine fois, vérifiez s’il existe déjà une borne de recharge DATS 24, car nous étendons progressivement notre réseau.',
      },
    ],
  },
  {
    name: 'fcev',
    title: "L'hydrogène:",
    sub: "l'innovateur",
    convenience: 20,
    description:
      "Une voiture à hydrogène fonctionne à l'électricité à partir d'hydrogène, que l'on recharge. Il possède une pile à combustible qui transforme l'hydrogène en électricité et en eau. L'électricité alimente le moteur électrique, l'eau inoffensive disparaît par l'échappement.",
    env: [
      "En termes d'impact climatique et environnemental, l'hydrogène est classé après les voitures électriques en environnement urbain et dans les dernières technologies en environnement rural, à cause de la grande quantité d’électricité nécessaire pour produire l’hydrogène. Si l'hydrogène est produit avec de l'électricité issue de source renouvelable (via l'électrolyse), la production et l'utilisation du carburant génère une quantité faible de CO2 et d’autres émissions.",
    ],
    eco1: "Les voitures à hydrogène sont encore chères à l'achat aujourd'hui, car la technologie est en voie de développement. Un ravitaillement en carburant coûte environ 9 €/kg. Cela semble beaucoup, mais les voitures sont économiques : pour 100 km, une voiture à hydrogène coûte environ 9 euros en carburant. Sur la même distance, une voiture à essence de la même fourchette de prix coûte 13 à 14 euros. En tant que professionnel, vous pouvez également taxer cette voiture à 100%, car elle n'émet pas de gaz d'échappement. ",
    eco2: "En plus de votre achat, vous aurez des coûts récurrents tels que l'entretien, le carburant et les taxes. Vous ajoutez 20 % du prix d'achat à votre budget de consommation.",
    conv1:
      "Pour l'instant, l'hydrogène ne peut être rechargé que dans quelques stations-service en Belgique, dont DATS\u00a024 à Halle, Wilrijk et Haasrode. Dans d'autres pays, le réseau est déjà plus étendu. Dans les mois à venir, d'autres stations d'hydrogène seront ouvertes dans notre pays, dont 3 à DATS\u00a024 :",
    conv3:
      "Le grand avantage est que vous faites le plein de la même manière que le diesel, l'essence et le CNG. Il nécessite donc peu d'adaptation de vos habitudes et ne requiert aucune installation d'infrastructure à la maison ou au travail.",
    conv_list: ['Erpe-Mere', 'Herve', 'Ollignies'],
    conv2: 'Comment faire le plein d’hydrogène ?',
    conv_video: 'https://www.youtube.com/embed/lngzbAAG6oA',
    bonus_list: [
      {
        type: 'list',
        text: "Avec une voiture à hydrogène, vous économisez surtout pour l'environnement et l'espace vital. Les voitures sont également économes en carburant par nature, et vous pouvez y contribuer en suivant les conseils d'éco-conduite ci-dessous.",
      },
      {
        type: 'tip',
        text: "Certaines options ont un impact sur la consommation d'une voiture. Pensez, par exemple, à une barre de remorquage (plus de poids) et à des vitres arrière, des sièges et des rétroviseurs latéraux chauffants (consommation d'électricité). Cela peut coûter plus cher en carburant par 100 km. ",
      },
      {
        type: 'tip',
        text: 'utilisez intelligemment votre ordinateur de bord. En surveillant votre consommation actuelle pendant la conduite, vous apprenez à adapter votre style de conduite et votre consommation diminuera.',
      },
      {
        type: 'tip',
        text: "bien que les voitures à hydrogène fonctionnent également à l'électricité, l'autonomie n'est pas affectée par les températures hivernales, par exemple. À cet égard, cette voiture a un avantage sur les voitures à moteur électrique.",
      },
    ],
    extra_list: [
      {
        type: 'list',
        text: "Prenez le temps d'explorer les deux voitures à hydrogène disponibles : la Hyundai Nexo et la Toyota Mirai.",
      },
      {
        type: 'inline-link',
        text: "N'oubliez pas de lire ",
        text2: 'notre guide de démarrage',
        text3: "sur l'hydrogène",
        text4: '',
        url: 'https://customer.dats24.be/wps/portal/datscustomer/fr/dats24/mobility/h2/opening#Z7_3P141GS0N8VRD0Q340Q242O070',
      },
    ],
  },
];

const enginesNl = [
  {
    name: 'diesel',
    title: 'Diesel:',
    sub: 'de marathonloper',
    convenience: 80,
    description:
      'Een dieselauto heeft een verbrandingsmotor en is geschikt voor lange afstanden. Je vindt diesel in elk tankstation en voor bijna elk automodel. De meeste nieuwe dieselauto’s tanken ook AdBlue, dat schadelijke stoffen (zoals stikstofoxiden) uit de uitlaatgassen haalt. ',
    warning:
      'Hou er rekening mee dat dieselauto’s ontmoedigd worden door hun uitstoot.',
    env: [
      'Diesel is de minst duurzame brandstof, met een uitstoot van CO2, stikstofoxiden (NOx) en fijnstof. Dankzij AdBlue in de meeste moderne dieselwagens worden de stikstofoxiden wel afgebroken tot schadeloze stikstof en waterdamp. De CO2-uitstoot blijft echter dezelfde.',
    ],
    eco1: 'Je vindt dieselwagens in de meeste prijscategorieën van nieuwe wagens. Voor kleine dieselauto’s daalt het aanbod wel. Moderne dieselmotors verbruiken minder en voldoen aan strengere uitstootnormen (bvb. Euro 6). Het zijn deze normen die bepalen of je auto al dan niet in een lage-emissiezone (LEZ) mag rijden. Een dieselwagen verbruikt bovendien gemiddeld 15 % minder brandstof dan een gelijkaardig benzinemodel.',
    eco2: 'Bovenop je aankoop heb je terugkerende kosten als onderhoud, brandstof en taksen. Om een idee te hebben van het verbruiksbudget voor dieselwagens pas je deze vuistregel toe (berekend op 9,5 jaar gebruik van de auto):',
    eco_list: [
      'Dieselauto’s die rond € 25.000 aankoopprijs zitten: ruim 80 % van de aankoopprijs als verbruiksbudget.',
      'Het verbruiksbudget daalt omgekeerd evenredig met de aankoopprijs. Een dieselwagen van rond € 60.000: zo’n 40 tot 50 % van de aankoopprijs als verbruiksbudget.',
    ],
    conv1:
      'Als je met een dieselwagen rijdt, kan je rekenen op een breed netwerk tankstations in binnen- en buitenland. Je hebt ook de grootste autonomie met een dieselwagen. Sommige modellen kunnen tot 1.200 km rijden met één tankbeurt. Tanken gebeurt op de vertrouwde manier: aan de pomp met een tankpistool. Ook AdBlue tank je vlot en proper aan de pomp bij DATS 24.',
    conv2: 'Hoe tank je AdBlue?',
    conv_video: 'https://www.youtube.com/embed/9b0X8nyCQ-w',
    bonus_list: [
      {
        text: 'Je kan je aankoopbudget flexibel inzetten. Je hebt nl. een brede keuze aan merken en modellen.  ',
        type: 'list',
      },
      {
        type: 'list',
        text: 'Je spaart jaarlijks minstens € 10 euro uit door AdBlue aan de pomp te tanken i.p.v. uit een jerrycan. Per liter spaar je nl. iets meer dan € 1 uit aan de pomp, wetende dat je je AdBlue jaarlijks minstens 2 keer bijvult met minstens 5 liter. ',
      },
      {
        type: 'tip',
        text: 'check je bandenspanning maandelijks. Zo kan je 2 tankbeurten per jaar uitsparen. ',
      },
      {
        type: 'tip',
        text: 'hou je auto (ook de koffer) zo leeg mogelijk. Voor elke 100 kg die je niet extra mee hebt, bespaar je 7 % brandstof.',
      },
    ],
    extra_list: [
      {
        type: 'list',
        text: 'Check zeker welke euronorm de auto heeft. Nieuwe dieselauto’s hebben bijna altijd euronorm 6, zo kan je ook in de lage-emissiezones (LEZ) zorgeloos binnen.',
      },
      {
        type: 'tip',
        text: 'bepaalde opties hebben invloed op het verbruik van een auto. Denk zeker kritisch na over bvb. een panoramisch glazen dak (meer gewicht) en verwarmde achterruit, stoelen en zijspiegels (elektriciteitsverbruik). Dat kan een halve liter meer brandstof kosten per 100 km. ',
      },
      {
        type: 'inline-link',
        text: 'Ontdek alvast alle',
        text2: ' DATS 24-tankstations',
        text3: ' met AdBlue op je route.',
        text4: '',
        url: 'https://customer.dats24.be/wps/portal/datscustomer/nl/b2c/locator?featureId=58',
      },
    ],
  },
  {
    name: 'petrol',
    title: 'Benzine:',
    sub: 'de klassieker',
    convenience: 80,
    description:
      'Een benzineauto heeft een verbrandingsmotor en is vooral geschikt voor korte afstanden. Je vindt benzine overal en voor elk automodel. Als je specifiek op zoek bent naar een kleine auto, vind je je gading ook eerder bij benzinewagens dan bij dieselauto’s, waar het aanbod merkbaar daalt.',
    warning:
      'Hou er rekening mee dat benzineauto’s ontmoedigd worden door hun uitstoot.',
    env: [
      'Op diesel (en LPG) na is benzine de minst propere brandstof, met een hoge uitstoot van CO2, stikstofoxiden (NOx) en fijnstof.',
    ],
    eco1: 'Je vindt benzinewagens in alle prijscategorieën van nieuwe wagens. Je hebt dus een brede keuze in merk, model en uitvoering. Benzinewagens verbruiken wel de meeste brandstof per liter (of kilogram) van alle aandrijvingen die vandaag bestaan.',
    eco2: 'Bovenop je aankoop heb je terugkerende kosten als onderhoud, brandstof en taksen. Om een idee te hebben van het verbruiksbudget voor benzinewagens pas je deze vuistregel toe (berekend op 9,5 jaar gebruik van de auto):',
    eco_list: [
      'Benzineauto’s die rond € 25.000 aankoopprijs zitten: zo’n 110 % van de aankoopprijs als verbruiksbudget.',
      'Het verbruiksbudget daalt omgekeerd evenredig met de aankoopprijs. Een benzinewagen van rond € 60.000: zo’n 70 % van de aankoopprijs als verbruiksbudget.',
    ],
    conv1:
      'Benzine is met een reden de klassieker. De eerste ‘automobielen’ met verbrandingsmotor reden op benzine. En de manier van tanken is hoe we het vandaag allemaal kennen. Je kan rekenen op een breed netwerk tankstations in binnen- en buitenland.',
    conv2: 'Benzine tanken in je buurt',
    conv_url:
      'https://customer.dats24.be/wps/portal/datscustomer/nl/b2c/locator',
    bonus_list: [
      {
        type: 'list',
        text: 'Je kan je aankoopbudget flexibel inzetten. Je hebt nl. een brede keuze aan merken en modellen. ',
      },
      {
        type: 'list',
        text: 'Check op het gelijkvormigheidsattest of in de tankklep welke benzine is aanbevolen voor de auto. Als E10 (EURO 95) volstaat, hoef je zeker geen E5 (EURO 98) te tanken. Op een tank van 50 liter scheelt je dat al snel bijna 10 % in budget.',
      },
      {
        type: 'tip',
        text: 'check je bandenspanning maandelijks. Zo kan je 2 tankbeurten per jaar uitsparen.',
      },
      {
        type: 'tip',
        text: 'hou je auto (ook de koffer) zo leeg mogelijk. Voor elke 100 kg die je niet extra mee hebt, bespaar je 7 % brandstof.',
      },
    ],
    extra_list: [
      {
        type: 'tip',
        text: 'bepaalde opties hebben invloed op het verbruik van een auto. Denk zeker kritisch na over bvb. een dakkoffer of het type banden (laagprofielbanden of brede banden zorgen voor meer wrijving) en verwarmde elementen als de achterruit, stoelen en zijspiegels (elektriciteitsverbruik). Dat kan een halve liter meer brandstof kosten per 100 km. ',
      },
      {
        type: 'inline-link',
        text: 'Ontdek alvast alle ',
        text2: 'DATS\u00a024-tankstations ',
        text3: 'op je route.',
        text4: '',
        url: 'https://customer.dats24.be/wps/portal/datscustomer/nl/b2c/locator',
      },
    ],
  },
  {
    name: 'cng',
    title: 'CNG:',
    sub: 'de alternatieve brandstof',
    convenience: 80,
    description:
      'De verbrandingsmotor van deze auto rijdt op CNG en benzine. Die tank je beide gewoon bij wanneer nodig. De CO2-uitstoot van CNG is tot 30 % minder dan bij diesel en benzine en de uitstoot van stikstofoxiden (NOx) tot 76 %. De uitstoot van fijnstof is tot 77 % minder.',
    env: [
      'CNG stoot minder CO2 uit dan diesel en benzine, en veel minder stikstofoxiden (NOx) en fijnstof. De voorwaarde is wel dat je de auto maximaal op CNG laat rijden, en niet op de benzinetank.',
    ],
    eco1: 'Een CNG-wagen zit qua aankoopbudget tussen een benzine- en dieselwagen. Je vindt ze bij o.a. grote Europese automerken (Audi, Seat en Volkswagen) in verschillende modellen en prijsklassen. Het aanbod is de laatste paar jaar wel aan het verminderen. Alvast goed om weten: met 1 kg CNG rij je verder dan met 1 l diesel.',
    eco2: 'Bovenop je aankoop heb je terugkerende kosten als onderhoud, brandstof en taksen. Om een idee te hebben van het verbruiksbudget voor CNG-wagens pas je deze vuistregel toe (berekend op 9,5 jaar gebruik van de auto):',
    eco_list: [
      'CNG-auto’s met een aankoopprijs tot € 25.000: ruim 80 % van de aankoopprijs als verbruiksbudget.',
      'Het verbruiksbudget daalt omgekeerd evenredig met de aankoopprijs. Een CNG-wagen van meer dan € 40.000: zo’n 60 % van de aankoopprijs als verbruiksbudget.',
    ],
    conv1:
      'CNG is een goede match voor wie duurzamer wil rijden en zijn tankgewoontes wil houden, een echte allemansvriend. CNG is duurzamer dan diesel en benzine en even vertrouwd in de manier waarop je tankt en rijdt. In België is er een breed netwerk CNG-tankstations, het merendeel bij DATS 24. Ook in de meeste buurlanden vind je CNG-tankstations. Vooral in Italië kan je rekenen op een breed netwerk.',
    conv2: 'Hoe tank je CNG? ',
    conv_video: 'https://www.youtube.com/embed/2rd_bA-Ao_s',
    conv4: 'CNG tanken in je buurt',
    conv_url2:
      'https://customer.dats24.be/wps/portal/datscustomer/nl/b2c/locator?featureId=13',
    bonus_list: [
      {
        type: 'list',
        text: 'Je bespaart t.o.v. diesel (de minst duurzame brandstof) een beduidende uitstoot van CO2 (16 %), NOx (76 %) en fijnstof (72 %).',
      },
      {
        type: 'list',
        text: 'Netbeheerder Fluvius is al een tijdje bezig met de overstap van laagcalorisch naar hoogcalorisch (energierijker) gas. Je vindt zo ook hoogcalorische CNG in steeds meer tankstations. Je betaalt er iets meer voor, maar je rijdt er ook verder mee, omdat hoogcalorische CNG meer energie bevat. In de tankstationzoeker van DATS 24 ontdek je welk type CNG op jouw route aangeboden wordt. ',
      },
      {
        type: 'tip',
        text: 'hou zo veel mogelijk een constante snelheid aan. Moet je afremmen? Los het gaspedaal gewoon. Zo zakt je brandstofverbruik. ',
      },
      {
        type: 'tip',
        text: 'omgekeerd kan je ook zo veel mogelijk de opgebouwde snelheid van de auto gebruiken. Kom je aan een afdaling? Het kan volstaan om je voet van het gaspedaal te halen en de auto te laten bollen. En zo gebruik je weer minder brandstof.',
      },
    ],
    extra_list: [
      {
        type: 'list',
        text: 'Check welke fabrikanten CNG-wagens aanbieden en of je daarbinnen het geschikte model voor jou vindt. ',
      },
      {
        type: 'inline-link',
        text: 'Ontdek alvast alle ',
        text2: 'DATS\u00a024-tankstations',
        text3: ' met CNG op je route.',
        text4: '',
        url: 'https://customer.dats24.be/wps/portal/datscustomer/nl/b2c/locator?featureId=13',
      },
    ],
  },
  {
    name: 'hev',
    title: 'Hybride:',
    sub: 'de stadskenner',
    convenience: 50,
    description:
      'Een hybride (HEV) rijdt op een verbrandingsmotor en elektromotor zonder stekker. Hybrides zijn trouwens altijd automaten, zoals alle elektrische auto’s. Hij is op zijn best op korte afstanden. Hij laadt zichzelf op tijdens het rijden. Deze auto tank je bij. Als hij elektrisch rijdt, stoot hij niets uit.',
    env: [
      'De energie die vrijkomt als je remt, wordt deels gerecupereerd in de batterij, die de elektromotor voedt. Het verbruik van een hybride is zo gunstiger dan dat van een auto op benzine of diesel. Het extra gewicht van de elektromotor en de batterij trekt het verbruik wel weer omhoog. De sleutel is om zo bewust mogelijk te rijden. Ontdek bij de bonuspunten alvast enkele ecodriving tips.',
    ],
    eco1: 'Een hybride auto is iets duurder dan een wagen op benzine of diesel. Je vindt ze bij verscheidene automerken en in heel wat verschillende modellen. Voor het tanken hoef je enkel rekening te houden met de kost van benzine of diesel. De kilometers die je op elektriciteit rijdt, zijn zelf opgewekt en dus gratis.',
    eco2: 'Bovenop je aankoop heb je terugkerende kosten als onderhoud, brandstof en taksen. Om een idee te hebben van het verbruiksbudget voor hybrides pas je deze vuistregel toe (berekend op 9,5 jaar gebruik van de auto):',
    eco_list: [
      'Hybride auto’s die rond € 25.000 aankoopprijs zitten: zo’n 80 % van de aankoopprijs als verbruiksbudget.',
      'Het verbruiksbudget daalt omgekeerd evenredig met de aankoopprijs. Een hybride auto van meer dan € 60.000: zo’n 45 % van de aankoopprijs als verbruiksbudget.',
    ],
    conv1:
      'Een hybride laadt zichzelf op terwijl je rijdt, bvb. als je remt. Dat gebeurt automatisch en je merkt er niks van tijdens het rijden. Jij hoeft enkel benzine bij te tanken voor de verbrandingsmotor. Als je rustig en zuinig rijdt haal je alles uit deze auto. En je tankgewoontes veranderen helemaal niet. ',
    conv2: 'Benzine tanken in je buurt',
    conv_url:
      'https://customer.dats24.be/wps/portal/datscustomer/nl/b2c/locator',
    bonus_list: [
      {
        type: 'list',
        text: 'Hoewel hybrides iets duurder zijn dan traditionele wagens, kan je je aankoopbudget redelijk flexibel inzetten. Je hebt nl. een brede keuze aan merken en modellen. ',
      },
      {
        type: 'tip',
        text: 'combineer je trajecten. Als je boodschappen doet en er is een tankstation vlakbij, kan je dan evengoed bijtanken. Dat is een rit uitgespaard.',
      },
      {
        type: 'tip',
        text: 'start de auto pas als jij en je passagiers vertrekkensklaar zijn. Zo verbruikt je wagen geen brandstof door stationair te staan draaien.',
      },
    ],
    extra_list: [
      {
        type: 'list',
        text: 'Check met je verdeler op welke brandstof de hybride rijdt: is het benzine of diesel? De meeste hybrides rijden op elektriciteit met benzine, maar er zijn uitzonderingen. Diesel is bij regel iets duurder dan benzine E10 (EURO 95).',
      },
      {
        type: 'list',
        text: 'Check ook met je verdeler hoeveel kilometer de batterij in elektriciteit kan leveren. Tussen modellen is er een verschil in elektrisch rijbereik door grootte van de batterij, precieze gewicht van de auto … ',
      },
      {
        type: 'inline-link',
        text: 'Ontdek alvast alle ',
        text2: 'DATS\u00a024-tankstations ',
        text3: 'op je route.',
        text4: '',
        url: 'https://customer.dats24.be/wps/portal/datscustomer/nl/b2c/locator',
      },
    ],
  },
  {
    name: 'phev',
    title: 'Plug-in hybride:',
    sub: 'de combinatiekampioen',
    convenience: 50,
    description:
      'Een plug-in hybride (PHEV) rijdt op een elektromotor, gevoed door een batterij, en een verbrandingsmotor. Deze auto tank je dus bij en laad je op met een stekker. Zolang hij elektrisch rijdt, stoot hij niets uit.',
    warning:
      'Moet de auto een caravan of aanhangwagen kunnen trekken? Check met je verdeler of jouw model dat kan.',
    env: [
      'Plug-in hybride rijden betekent dat je lokaal geen CO2 uitstoot terwijl je op elektriciteit rijdt. Als je ook nog eens 100 % groene stroom bijlaadt, haal je echt alles uit deze auto op vlak van klimaat en leefmilieu. Omgekeerd is het wel zo dat je verbruik sterk stijgt als je op de verbrandingsmotor rijdt. Met een volledig opgeladen elektromotor kan een plug-in hybride zo’n 40 tot 100 km op elektriciteit rijden. Voor heel wat mensen volstaat dat voor hun dagelijkse verplaatsingen. ',
      'Je kan dus kiezen om je dagelijkse ritten elektrisch te doen en enkel voor langere trajecten de verbrandingsmotor te gebruiken. Ecodriving speelt hierin wel een grote rol. Ontdek bij de bonuspunten alvast enkele ecodriving tips.',
    ],
    eco1: 'Een plug-in hybride auto is duurder dan een wagen op benzine of diesel. Je vindt ze bij steeds meer automerken en in verschillende modellen. Wat de brandstof betreft, betaal je zowel voor de benzine die je bijtankt als de elektriciteit die je laadt. Op voorwaarde dat je maximaal elektrisch rijdt, ligt de verbruikskost lager dan bij benzinewagens. Je vermijdt nl. veel kilometers op benzine en elektriciteit laden is goedkoper dan benzine tanken.',
    eco2: 'Bovenop je aankoop heb je terugkerende kosten als onderhoud, brandstof en taksen. Om een idee te hebben van het verbruiksbudget voor plug-in hybrides pas je deze vuistregel toe (berekend op 9,5 jaar gebruik van de auto):',
    eco_list: [
      'Plug-in hybride auto’s, onafhankelijk van het aankoopbedrag: 20 tot 40 % van de aankoopprijs als verbruiksbudget. Het verschil in percentage hangt af van het aantal kilometer dat je elektrisch aflegt.',
    ],
    conv1:
      'Het tanken bij een plug-in hybride ken je al. Het elektrisch bijladen is misschien nog nieuw. Ook het rijden met een plug-in hybride kan even wennen zijn, omdat je bij sommige modellen met hetzelfde pedaal gas geeft en afremt (als je het pedaal loslaat). Je zal een plug-in hybride vooral thuis opladen, en onderweg of op het werk bijladen. Hiervoor loont het de moeite om te ontdekken welke laadpunten er op je route zijn.',
    conv2: 'Benzine tanken in je buurt',
    conv_url:
      'https://customer.dats24.be/wps/portal/datscustomer/nl/b2c/locator',
    conv4: 'Elektrisch laden in je buurt',
    conv_url2: 'https://msp.dats24.be/m/#/charging/stations?start',
    bonus_list: [
      {
        type: 'list',
        text: 'Je hebt een brede keuze aan merken en modellen.',
      },
      {
        type: 'list',
        text: 'Laad zoveel mogelijk elektrisch op: behalve een lagere prijs per kilometer is er ook geen lokale uitstoot. Als je daarenboven 100 % groene stroom oplaadt, is de energiebron zelf ook duurzaam.',
      },
      {
        type: 'tip',
        text: 'pas je rijstijl aan om je elektrisch rijbereik te maximaliseren. Rij rustig en gebruik elektrische elementen (verwarming, airco, stoel- en ruitverwarming …) enkel wanneer echt nodig. ',
      },
      {
        type: 'tip',
        text: 'gebruik je boordcomputer slim. Door je actuele verbruik te monitoren tijdens het rijden, leer je je rijstijl aanpassen en zakt je verbruik. Extra handig bij plug-in hybrides, waarmee je liefst zo veel mogelijk elektrisch rijdt.  ',
      },
      {
        type: 'inline-link',
        text: 'Check ook onze andere ',
        text2: 'besparingstips',
        text3: 'voor elektrische auto’s,',
        text4: 'want bvb. de winterkou verlaagt je rijbereik.',
        url: 'https://customer.dats24.be/wps/portal/datscustomer/nl/dats24/mobility/wintercheck',
      },
    ],
    extra_list: [
      {
        type: 'list',
        text: 'Check met je verdeler of het elektrische rijbereik je dagelijkse kilometers dekt (naar je werk, de school, de winkel …).',
      },
      {
        type: 'inline-link',
        text: 'Ontdek ',
        text2: 'welke laadpas ',
        text3: 'het beste bij jou past. Hierbij stel je jezelf 2 vragen:',
        url: 'https://customer.dats24.be/wps/portal/datscustomer/nl/dats24/mobility/stappen-naar-elektrisch-rijden',
      },
      {
        type: 'num',
        text: '1.	Waar ga ik onderweg het vaakst bijladen?',
      },
      {
        type: 'num',
        text: '2.	Kan ik met de laadpas ook laadpunten van andere aanbieders activeren? De DATS 24-laadpas is bruikbaar bij 9 op de 10 laadpunten in België en bij een breed netwerk in Europa.',
      },
      {
        type: 'inline-link',
        text: 'Ontdek alvast alle ',
        text2: 'laadpunten',
        text3: ' waar je met de DATS\u00a024-laadpas laadt.',
        text4: '',
        url: 'https://msp.dats24.be/m/#/charging/stations?start',
      },
      {
        type: 'list',
        text: 'Heb je een vaste Colruyt-, OKay- of BioPlanet-winkel? Of ga je soms naar DreamLand? Check volgende keer of er al een laadpunt van DATS 24 staat, want we zetten er steeds meer bij.',
      },
    ],
  },
  {
    name: 'bev',
    title: 'Batterij-elektrisch:',
    sub: 'de stroomversneller',
    convenience: 20,
    description:
      'Een batterij-elektrische auto (BEV) rijdt 100 % elektrisch. Hij heeft een elektromotor die gevoed wordt door een grote batterij (tot meer dan 100 kWh) i.p.v. een verbrandingsmotor. Je laadt deze auto bij aan een laadpunt. Hij heeft geen lokale uitstoot.',
    warning:
      'Moet de auto een caravan of aanhangwagen kunnen trekken? Check met je verdeler of jouw model dat kan.',
    env: ['Samen met waterstof is batterij-elektrisch vandaag de meest propere manier van rijden die bestaat, zonder uitlaatgassen. Als je de auto ook nog eens bijlaadt met 100 % groene stroom, haal je er maximaal winst uit voor het klimaat en leefmilieu. '],
    eco1: 'Batterij-elektrische auto’s zijn vandaag nog duur in aankoop. De brandstofkost is echter goedkoper dan bvb. een benzinewagen over de volledige levensduur (9,5 jaar). Als je bovendien thuis bijlaadt met zelfopgewekte elektriciteit (bvb. via zonnepanelen) is bijladen groen en voordeliger. Als professional kan je deze auto ook 100 % fiscaal inbrengen, net omdat hij geen uitlaatgassen uitstoot.',
    eco2: 'Bovenop je aankoop heb je terugkerende kosten als onderhoud, brandstof en taksen. Om een idee te hebben van het verbruiksbudget voor batterij-elektrische auto’s pas je deze vuistregel toe (berekend op 9,5 jaar gebruik van de auto):',
    eco_list: [
      'Batterij-elektrische auto’s die rond € 25.000 aankoopprijs zitten: zo’n 30 % van de aankoopprijs als verbruiksbudget.',
      'Het verbruiksbudget daalt omgekeerd evenredig met de aankoopprijs. Een batterij-elektrische auto van boven de € 60.000: zo’n 10 % van de aankoopprijs als verbruiksbudget.',
    ],
    conv1:
      'Volledig elektrisch rijden vraagt een grotere aanpassing van je gewoonten dan andere brandstoffen. Het rijden is even wennen omdat je bij deze wagen met hetzelfde pedaal gas geeft en afremt (als je het pedaal loslaat). Je bereidt lange trajecten best ook meer voor in termen van laadpunten en rijbereik. Zeker voor deze auto’s is een zuinige rijstijl de sleutel om zo lang mogelijk op 1 laadbeurt te rijden. Ontdek bij de bonuspunten alvast enkele ecodriving tips. In België is je rijbereik alvast ruim voldoende om overdag rond te rijden en ’s avonds thuis te laden. ',
    conv3:
      'Intussen groeit het netwerk laadpunten in België en de omringende landen. Er zijn ook steeds meer laadmogelijkheden beschikbaar voor thuis, op voorwaarde dat je de plaats hebt voor een laadpunt.',
    conv2: 'Elektrisch laden in je buurt',
    conv_url: 'https://msp.dats24.be/m/#/charging/stations?start',
    bonus_list: [
      {
        type: 'list',
        text: 'Met een batterij-elektrische auto bespaar je vooral voor het leefmilieu en klimaat. ',
      },
      {
        type: 'tip',
        text: 'pas je rijstijl aan om je elektrisch rijbereik te maximaliseren. Rij rustig. Gebruik de airco, verwarming, stoel- en ruitverwarming enkel wanneer echt nodig.',
      },
      {
        type: 'tip',
        text: 'gebruik je cruise control zo vaak mogelijk. Je auto houdt een constante snelheid aan, wat je verbruik drastisch verlaagt. Zeker bij elektrisch aangedreven auto’s is dat een handige methode, want zo haal je alles uit je rijbereik.',
      },
      {
        type: 'link',
        text: 'Check ook onze andere ',
        text2: 'besparingstips',
        text3: 'voor elektrische auto’s, ',
        text4: 'want bvb. de winterkou verlaagt je rijbereik.',
        url: 'https://customer.dats24.be/wps/portal/datscustomer/nl/dats24/mobility/wintercheck',
      },
    ],
    extra_list: [
      {
        type: 'list',
        text: 'Check met je verdeler of het elektrische rijbereik je dagelijkse kilometers dekt (naar je werk, de school, de winkel …).',
      },
      {
        type: 'inline-link',
        text: 'Ontdek ',
        text2: 'welke laadpas ',
        text3: 'het beste bij jou past. Hierbij stel je jezelf 2 vragen: ',
        url: 'https://customer.dats24.be/wps/portal/datscustomer/nl/dats24/mobility/stappen-naar-elektrisch-rijden',
      },
      {
        type: 'num',
        text: '1.	Waar ga ik onderweg het vaakst bijladen?',
      },
      {
        type: 'num',
        text: '2.	Kan ik met de laadpas ook laadpunten van andere aanbieders activeren? De DATS 24-laadpas is bruikbar bij 9 op de 10 laadpunten in België en bij een breed netwerk in Europa.',
      },
      {
        type: 'inline-link',
        text: 'Ontdek alle ',
        text2: 'laadpunten',
        text3: ' waar je met de DATS\u00a024-laadpas laadt.',
        text4: '',
        url: 'https://msp.dats24.be/m/#/charging/stations?start',
      },
      {
        type: 'list',
        text: 'Heb je een vaste Colruyt-, OKay- of BioPlanet-winkel? Of ga je soms naar DreamLand? Check volgende keer of er al een laadpunt van DATS 24 staat, want we zetten er steeds meer bij.',
      },
    ],
  },
  {
    name: 'fcev',
    title: 'Waterstof:',
    sub: 'de vernieuwer',
    convenience: 20,
    description:
      'Een waterstofauto rijdt op elektriciteit uit waterstof, die je tankt. Hij heeft een brandstofcel die waterstof omzet in elektriciteit en water. De elektriciteit voedt de elektromotor, het onschadelijke water verdwijnt door de uitlaat.',
    env: ["Waterstof komt in termen van impact op het klimaat en leefmilieu in stedelijke omgevingen na elektrische auto's en in landelijke omgevingen op de laatste plaats vanwege de grote hoeveelheid elektriciteit die nodig is om waterstof te produceren. Als de waterstof geproduceerd wordt met hernieuwbare energie (via elektrolyse), komt er nauwelijks CO2 en andere uitstoot te pas aan de productie en het gebruik van de brandstof."],
    eco1: 'Waterstofwagens zijn vandaag nog duur in aankoop, omdat de technologie in volle ontwikkeling is. Een tankbeurt kost zo’n € 9/kg. Dat lijkt veel, maar de auto’s rijden heel zuinig: per 100 km kost een auto op waterstof aan brandstof ongeveer € 9. Op dezelfde afstand kost een benzinewagen uit dezelfde prijsklasse € 13 tot € 14. Als professional kan je deze auto ook 100 % fiscaal inbrengen, omdat hij geen uitlaatgassen uitstoot. ',
    eco2: 'Bovenop je aankoop heb je terugkerende kosten als onderhoud, brandstof en taksen. Je rekent voor je verbruiksbudget hier 20 % van de aankoopprijs bij.',
    conv1:
      'Je tankt waterstof voorlopig maar in enkele tankstations in België, waaronder die van DATS\u00a024 in Halle, Wilrijk en Haasrode. In het buitenland is het netwerk groter. De komende maanden zullen er wel meer waterstofstations openen in ons land, alvast 3 bij DATS\u00a024:',
    conv3:
      'Het grote voordeel is dat je op dezelfde manier tankt als diesel, benzine en CNG. Het vraagt dus weinig aanpassing van je gewoontes en vraagt geen installatie van infrastructuur bij je thuis of op het werk.',
    conv_list: ['Erpe-Mere', 'Herve', 'Ollignies'],
    conv2: 'Hoe tank je waterstof?',
    conv_video: 'https://www.youtube.com/embed/MP1NqA_cmss',
    bonus_list: [
      {
        type: 'list',
        text: 'Met een waterstofwagen bespaar je vooral voor het milieu en leefklimaat. De auto’s rijden van nature ook zuinig en je kan nog een handje helpen met de ecodriving tips hieronder.',
      },
      {
        type: 'tip',
        text: 'bepaalde opties hebben invloed op het verbruik van een auto. Denk zeker kritisch na over bvb. een trekhaak (meer gewicht) en verwarmde achterruit, stoelen en zijspiegels (elektriciteitsverbruik). Dat kan meer brandstof kosten per 100 km.',
      },
      {
        type: 'tip',
        text: 'gebruik je boordcomputer slim. Door je actuele verbruik te monitoren tijdens het rijden, leer je je rijstijl aanpassen en zakt je verbruik.',
      },
      {
        type: 'tip',
        text: 'hoewel waterstofwagens ook rijden op elektriciteit, wordt het rijbereik niet beïnvloedt door bvb. wintertemperaturen. Op dat vlak heeft deze auto een streepje voor op elektrisch aangedreven wagens.',
      },
    ],
    extra_list: [
      {
        type: 'list',
        text: 'Neem de tijd om de 2 verkrijgbare waterstofwagens te verkennen: zowel de Hyundai Nexo als de Toyota Mirai.',
      },
      {
        type: 'inline-link',
        text: 'Lees zeker eens onze ',
        text2: 'startersgids',
        text3: ' over waterstof.',
        text4: '',
        url: 'https://customer.dats24.be/wps/portal/datscustomer/nl/dats24/mobility/h2/opening#Z7_3P141GS0N8VRD0Q340Q242O070',
      },
    ],
  },
];

export { enginesFr, enginesNl };
