import api from '@/utils/services/api';

export default class UserService {
  static async createUser(user) {
    try {
      return api.post('/users', { ...user });
    } catch (err) {
      console.error(err);
    }
  }

  static async unsubscribe(id) {
    try {
      await api.put(`/users/${id}`);
    } catch (err) {
      console.error(err);
    }
  }
}
