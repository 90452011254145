<template>
  <div class="row download-row">
    <div class="col-12 btn-center">
      <app-button @clicked="generateReport">{{
        $t('results.download')
      }}</app-button>
    </div>
    <div class="col-10 text-center">
      <p class="text-small mt-4">{{ $t('optin.safari') }}</p>
    </div>
  </div>
  <!-- Pdf Template -->
  <vue3-html2pdf
    :show-layout="false"
    :float-layout="true"
    :enable-download="true"
    :preview-modal="false"
    :pdf-quality="2"
    :manual-pagination="true"
    pdf-format="a4"
    pdf-orientation="portrait"
    pdf-content-width="800px"
    ref="html2Pdf"
    :html-to-pdf-options="options"
  >
    <template #pdf-content>
      <section class="pdf-item pdf-header">
        <div class="pdf-logos">
          <img
            style="width: 100px; margin-right: 1rem"
            :src="require('@/assets/img/logo-desktop.svg')"
          />
          <img
            style="width: 80px"
            :src="require('@/assets/img/weloop-logo.png')"
          />
        </div>
        <h1 class="pdf-title">
          {{ $t('pdf.title') }}
        </h1>
        <p class="pdf-subtitle mb-2">
          {{ $t('pdf.intro1') }} {{ $t('pdf.intro2') }}
        </p>
        <ul class="pdf-bullets">
          <li>&#10004; {{ $t('pdf.bullet1') }}</li>
          <li>&#10004; {{ $t('pdf.bullet2') }}</li>
          <li>&#10004; {{ $t('pdf.bullet3') }}</li>
          <li>&#10004; {{ $t('pdf.bullet4') }}</li>
        </ul>
        <p class="pdf-subtitle mb-4">{{ $t('pdf.conclusion') }}</p>
        <p class="pdf-footer pdf-footer__1">
          <a :href="$t('pdf.footer_link')" target="_blank">{{
            $t('pdf.footer_link')
          }}</a>
          - {{ $t('pdf.footer_link_text') }}
        </p>
      </section>
      <span class="html2pdf__page-break"></span>
      <section
        class="pdf-item pdf-card"
        v-for="result in results"
        :key="result.position"
      >
        <div class="card result-card">
          <div class="card-position">
            <p>{{ result.position }}</p>
          </div>
          <div class="card-header">
            <p class="mt-2">
              <span class="text-bold">{{ result.score }}%</span> match
            </p>
            <div>
              <h2>{{ result.engine.title }}</h2>
              <p>{{ result.engine.sub }}</p>
            </div>
          </div>
          <div class="card-body">
            <h2 class="pdf-card-title">{{ $t('results.report.title1') }}</h2>
            <p class="mb-1">{{ result.engine.description }}</p>
            <p v-if="result.engine.warning">
              <i class="bi bi-exclamation-circle-fill"></i
              >{{ result.engine.warning }}
            </p>
            <div class="pdf-indicators">
              <h2 class="pdf-card-title">{{ $t('results.report.title2') }}</h2>
              <img
                :src="require('@/assets/img/score-1.jpg')"
                v-if="result.envScore < 40"
              />
              <img
                :src="require('@/assets/img/score-3.jpg')"
                v-else-if="result.envScore > 70"
              />
              <img :src="require('@/assets/img/score-2.jpg')" v-else />
            </div>
            <p v-for="envItem in result.engine.env">{{ envItem }}</p>
            <p class="text-small">{{ $t('results.env.small1') }}</p>
            <span
              class="html2pdf__page-break"
              v-if="
                result.engine.name === 'bev' ||
                result.engine.name === 'phev' ||
                result.engine.name === 'diesel' ||
                result.engine.name === 'petrol'
              "
            ></span>
            <div
              class="pdf-indicators"
              :class="{
                'space-top':
                  result.engine.name === 'bev' ||
                  result.engine.name === 'phev' ||
                  result.engine.name === 'diesel' ||
                  result.engine.name === 'petrol',
              }"
            >
              <h2 class="pdf-card-title">{{ $t('results.report.title3') }}</h2>
              <img
                :src="require('@/assets/img/score-1.jpg')"
                v-if="result.ecoScore < 40"
              />
              <img
                :src="require('@/assets/img/score-3.jpg')"
                v-else-if="result.ecoScore > 70"
              />
              <img :src="require('@/assets/img/score-2.jpg')" v-else />
            </div>

            <p class="text-bold">{{ $t('results.eco.title1') }}</p>
            <p>{{ result.engine.eco1 }}</p>
            <span
              class="html2pdf__page-break"
              v-if="
                result.engine.name === 'cng' ||
                result.engine.name === 'hev' ||
                result.engine.name === 'fcev'
              "
            ></span>
            <p
              class="text-bold"
              :class="{
                'space-top':
                  result.engine.name === 'cng' ||
                  result.engine.name === 'hev' ||
                  result.engine.name === 'fcev',
              }"
            >
              {{ $t('results.eco.title2') }}
            </p>
            <p>{{ result.engine.eco2 }}</p>
            <ul v-if="result.engine.eco_list">
              <li v-for="item in result.engine.eco_list" :key="item">
                {{ item }}
              </li>
            </ul>
            <p class="text-small">{{ $t('results.eco.small1') }}</p>
            <span
              class="html2pdf__page-break"
              v-if="
                result.engine.name === 'bev' ||
                result.engine.name === 'phev' ||
                result.engine.name === 'diesel' ||
                result.engine.name === 'petrol'
              "
            ></span>
            <div
              class="pdf-indicators"
              :class="{
                'space-top':
                  result.engine.name === 'bev' ||
                  result.engine.name === 'phev' ||
                  result.engine.name === 'diesel' ||
                  result.engine.name === 'petrol',
              }"
            >
              <h2 class="pdf-card-title">{{ $t('results.report.title4') }}</h2>
              <img
                :src="require('@/assets/img/score-1.jpg')"
                v-if="result.engine.convenience < 40"
              />
              <img
                :src="require('@/assets/img/score-3.jpg')"
                v-else-if="result.engine.convenience > 70"
              />
              <img :src="require('@/assets/img/score-2.jpg')" v-else />
            </div>
            <p>{{ result.engine.conv1 }}</p>
            <ul v-if="result.engine.conv_list">
              <li
                v-for="item in result.engine.bonus_list"
                :key="item"
                :class="{ 'is-num': item.type === 'num' }"
              >
                <template v-if="item.type === 'tip'">
                  <span class="text-bold">{{ $t('results.tip') }}</span>
                  {{ item.text }}
                </template>
                <template v-if="item.type === 'list' || item.type === 'num'">
                  {{ item.text }}
                </template>
                <template v-if="item.type === 'link'">
                  <a :href="item.url" target="_blank">{{ item.text }}</a>
                </template>
                <template v-if="item.type === 'inline-link'">
                  {{ item.text }}
                  <a :href="item.url" target="_blank">{{ item.text2 }} </a
                  >{{ item.text3 }}
                </template>
              </li>
            </ul>
            <p v-if="result.engine.conv3">
              {{ result.engine.conv3 }}
            </p>
            <span
              class="html2pdf__page-break"
              v-if="
                result.engine.name === 'cng' ||
                result.engine.name === 'hev' ||
                result.engine.name === 'fcev'
              "
            ></span>
            <p
              class="text-bold"
              :class="{
                'space-top':
                  result.engine.name === 'cng' ||
                  result.engine.name === 'hev' ||
                  result.engine.name === 'fcev',
              }"
            >
              <a
                :href="result.engine.conv_url"
                target="_blank"
                class="text-bold pdf-card-link"
                v-if="result.engine.conv_url"
              >
                {{ result.engine.conv2 }}</a
              >
              <a
                :href="result.engine.conv_video"
                target="_blank"
                class="text-bold pdf-card-link"
                v-if="result.engine.conv_video"
              >
                {{ result.engine.conv2 }}</a
              >
              <a
                :href="result.engine.conv_url2"
                target="_blank"
                class="text-bold card-link"
                v-if="result.engine.conv_url2"
              >
                {{ result.engine.conv4 }}</a
              >
            </p>
            <p class="text-small">{{ $t('results.conv.small1') }}</p>
            <h2 class="pdf-card-title">
              {{ $t('results.report.title5') }}
            </h2>
            <ul>
              <li
                v-for="item in result.engine.bonus_list"
                :key="item"
                :class="[item.type === 'num' ? 'is-num' : '']"
              >
                <span class="text-bold" v-if="item.type === 'tip'">{{
                  $t('results.tip')
                }}</span
                ><span v-if="item.type !== 'link'">{{ item.text }}</span>
                <a
                  :href="item.url"
                  target="_blank"
                  v-if="item.type === 'link'"
                  >{{ item.text }}</a
                >
              </li>
            </ul>
            <span
              class="html2pdf__page-break"
              v-if="
                result.engine.name === 'bev' ||
                result.engine.name === 'phev' ||
                result.engine.name === 'diesel' ||
                result.engine.name === 'petrol'
              "
            ></span>
            <h2
              class="pdf-card-title"
              :class="{
                'space-top':
                  result.engine.name === 'bev' ||
                  result.engine.name === 'phev' ||
                  result.engine.name === 'diesel' ||
                  result.engine.name === 'petrol',
              }"
            >
              {{ $t('results.report.title6') }}
            </h2>
            <ul>
              <li
                v-for="item in result.engine.extra_list"
                :key="item"
                :class="{ 'is-num': item.type === 'num' }"
              >
                <template v-if="item.type === 'tip'">
                  <span class="text-bold">{{ $t('results.tip') }}</span>
                  {{ item.text }}
                </template>
                <template v-if="item.type === 'list' || item.type === 'num'">
                  {{ item.text }}
                </template>
                <template v-if="item.type === 'link'">
                  <a :href="item.url" target="_blank">{{ item.text }}</a>
                </template>
                <template v-if="item.type === 'inline-link'">
                  {{ item.text }}
                  <a :href="item.url" target="_blank">{{ item.text2 }} </a
                  >{{ item.text3 }}<br />
                  {{ item.text4 }}
                </template>
              </li>
            </ul>
          </div>
        </div>
        <span class="html2pdf__page-break" v-if="result.position !== 3"></span>
      </section>
    </template>
  </vue3-html2pdf>
</template>

<script>
import Vue3Html2pdf from 'vue3-html2pdf';
import CardIndicator from '@/components/results/CardIndicator';

export default {
  name: 'DownloadPdf',
  props: ['results'],
  components: {
    Vue3Html2pdf,
    CardIndicator,
  },
  data() {
    return {
      options: {
        enableLinks: true,
        filename: 'fuelguide.pdf',
      },
      lang: 'en',
    };
  },
  created() {
    this.lang = this.$i18n.locale;
    if (this.lang === 'fr') this.options.filename = 'guide-du-carburant.pdf';
    if (this.lang === 'nl') this.options.filename = 'brandstofwijzer.pdf';
  },
  methods: {
    generateReport() {
      this.$refs.html2Pdf.generatePdf();
    },
  },
};
</script>

<style lang="scss" scoped>
.download-row {
  margin-bottom: 3rem;
  .text-center {
    margin: 0 auto;
      .text-small {
    font-size: 14px;
    max-width: 800px;
    margin: 0 auto;
  }
  }
}
.pdf-header {
  justify-content: center;
  align-items: center;
  .pdf-title {
    text-align: center;
    font-size: 24px;
    padding-top: 3rem;
    padding-bottom: 2rem;
    margin: 0 auto;
  }
  .pdf-subtitle {
    font-size: 18px;
    max-width: 700px;
    margin: 0 auto;
  }
  .pdf-bullets {
    margin-left: 3rem;
  }
}
.pdf-card {
  padding: 2rem;
  .result-card {
    margin-top: 1rem;
    width: 100%;
    border: none;
    .card-header {
      h2 {
        font-size: 20px;
      }
      p {
        margin-top: -5px;
      }
    }
  }
  .card-body {
    .pdf-card-title {
      font-size: 20px;
      font-family: $accent-font;
      font-weight: 600;
      color: $dark-grey;
      margin-bottom: 1rem;
      margin-top: 1rem;
    }
    p.text-small {
      font-size: 12px;
      margin-bottom: 0.5rem;
    }
    li {
      list-style: disc;
    }
    li.is-num {
      list-style: none;
    }
    .space-top {
      margin-top: 2rem;
    }
    .pdf-card-link {
      color: $info;
    }
    a {
      color: $info;
    }
  }
}
.pdf-logos {
  padding: 2rem;
}

.pdf-footer {
  font-size: 16px;
  margin-left: 3rem;
  a {
    color: $info;
  }
  &__1 {
    margin-top: 25rem;
  }
}

.pdf-indicators {
  display: flex;
  h2 {
    margin-right: 1rem;
  }
  img {
    margin-bottom: 0.5rem;
  }
}
</style>
