const metaEn = {
    title: 'Fuel Guide',
    keywords: 'fuel, guide, ecology, cars',
    description:
      "What type of motor should you choose for your next car? The Fuel Guide helps you choose the fuel that's best for you in terms of durability, budget, and convenience.",
  };
  
  const metaFr = {
    title: 'Guide du Carburant',
    keywords: 'carburant, écologie, guide, voitures',
    description:
      'Quel type de moteur choisir pour votre prochaine voiture ? Le guide du carburant vous aide à opter pour le carburant qui vous convient le mieux en termes de durabilité, budget et commodité.',
  };
  
  const metaNl = {
    title: 'Brandstofwijzer',
    keywords: 'brandstof, gids, ecologie, auto',
    description:
      'Op welke brandstof rijdt je volgende auto? De brandstofwijzer helpt je de brandstof te kiezen die het beste bij je past op vlak van duurzaamheid, budget & gemak.',
  };
  
  const tags = [
    {
      selector: 'meta[property="og:title"]',
      type: 'title',
    },
    {
      selector: 'meta[property="twitter:title"]',
      type: 'title',
    },
    {
      selector: 'meta[name="description"]',
      type: 'description',
    },
    {
      selector: 'meta[property="og:description"]',
      type: 'description',
    },
    {
      selector: 'meta[property="twitter:description"]',
      type: 'description',
    },
    {
      selector: 'meta[name="keywords"]',
      type: 'keywords',
    },
  ];
  
  export { metaEn, metaFr, metaNl, tags };
  