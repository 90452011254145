<template>
  <a
    :href="`mailto:support@fuelguide.be?subject=Support&body=Session: ${sessionId}%0D%0AUser: ${userId}%0D%0A%0D%0APlease enter your message below%0D%0A------------------------%0D%0A%0D%0A`"
    ><slot
  /></a>
</template>

<script>
export default {
  name: 'AppSupportLink',
  data() {
    return {
      sessionId: '',
      userId: '',
    };
  },
  created() {
    this.getUserInfo();
  },
  methods: {
    getUserInfo() {
      this.sessionId = localStorage.getItem('session_id');
      this.userId = localStorage.getItem('user_id');
    },
  },
};
</script>

<style></style>
