export default {
	en: {
		name: 'EN',
		load: () => { return import('./en.json'); },
	},
	fr: {
		name: 'FR',
		load: () => { return import('./fr.json'); },
	},
	nl: {
		name: 'NL',
		load: () => { return import('./nl.json'); },
	},
};
