<template>
  <div
    class="card"
    :class="{ 'card-active': activated }"
    @click="setChoice(choice.value)"
  >
    <div class="card-content">
      <p>{{ choice.choice }} <span>{{ choice.sub }}</span></p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ChoiceItem',
  props: ['choice', 'activated'],
  emits: ['chosen', 'isActive'],
  data() {
    return {
      isActive: false,
    };
  },
  methods: {
    setChoice() {
      this.$emit('chosen');
      this.$emit('isActive');
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  margin-bottom: 0;
}
</style>
