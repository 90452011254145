<template>
  <el-dialog
    v-model="showModal"
    custom-class="cookies-card shadow"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
  >
    <div class="row">
      <div class="col-12 col-lg-8">
        <p class="cookies-text text-center">{{ $t('cookies.text') }}</p>
      </div>
      <div class="col-12 col-lg-4 cookies-links">
        <app-button @clicked="setAllCookies">
          {{ $t('cookies.consent') }}
        </app-button>
        <cookies-params @cookiesSet="showModal = false" />
      </div>
    </div>
  </el-dialog>
</template>

<script>
import CookieService from '@/utils/services/CookieService';
import CookiesParams from '@/components/rgpd/CookiesParams';
export default {
  name: 'CookiesCard',
  components: {
    CookiesParams,
  },
  data() {
    return {
      showModal: true,
    };
  },
  created() {
    const appCookies = this.$cookies.get('app_cookies');

    if (appCookies) {
      this.showModal = false;
    }
  },
  methods: {
    setAllCookies() {
      const session = {
        consent: true,
        date: new Date().toString(),
        analytics: true,
      };

      this.$cookies.set('app_cookies', JSON.stringify(session), '30d');

      this.$matomo.rememberCookieConsentGiven(); 
      CookieService.getSession(session).then(() => {
        this.showModal = false;
      });
    },
  },
};
</script>

<style lang="scss">
.cookies-card {
  width: 90% !important;
  border-radius: 4px !important;
  position: absolute !important;
  bottom: 0 !important;
  left: 5% !important;
  .el-dialog__header {
    display: none !important;
  }

  .cookies-text {
    font-size: 14px;
    margin-bottom: 2rem;
    white-space: normal !important;
  }

  .cookies-links {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    a {
      margin-top: 1rem;
      font-size: 14px;
      color: $dark-bg;
      text-decoration: underline;
      text-underline-offset: 5px;
    }
    button {
      font-size: 14px;
    }
  }
}

@include bp-up(md) {
  .cookies-card {
    width: 50% !important;
    .cookies-text {
      font-size: 16px;
    }

    .cookies-links {
      a {
        font-size: 16px;
      }
      button {
        font-size: 16px;
      }
    }
  }
}

.el-dialog__body {
  word-break: normal !important;
}

@include bp-up(lg) {
  .cookies-card {
    width: 70% !important;
  }
}

@include bp-up(xxl) {
  .cookies-card {
    width: 60% !important;
  }
}
</style>
