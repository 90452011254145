export default {

  state: {
    homePage: false
  },
  mutations: {
    setHomePage(state, payload) {
      state.homePage = payload;
    }
  },
  actions: {},
  getters: {},
};
