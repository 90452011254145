import api from '@/utils/services/api';
import { enginesFr, enginesNl } from '@/utils/data/engines';
import { percentageFr, percentageNl } from '@/utils/data/percentages';

export default class ResultService {
  static async createResults(user, answers) {
    try {
      return api.post('/results', {
        user,
        answers,
      });
    } catch (err) {
      console.error(err);
    }
  }

  static async getResults(id) {
    try {
      const { data } = await api.get(`/results/${id}`);
      return data;
    } catch (err) {
      console.error(err);
    }
  }

  static getEnginesText(lang) {
    return lang === 'fr' ? enginesFr : enginesNl;
  }

  static getPercentagesText(lang) {
    return lang === 'fr' ? percentageFr : percentageNl;
  }

  static getEnvPercentage(lang, env) {
    const percentage = this.getPercentagesText(lang);

    const envObject = percentage.find((item) => item.value === env);
    return envObject.choice;
  }

  static filterResults(lang, dbResults) {
    const results = [];

    const engines = this.getEnginesText(lang);

    dbResults.forEach((result, index) => {
      const engine = engines.find((item) => item.name === result.name);
      results.push({
        ...result,
        engine: engine,
        position: parseInt(index) + 1,
      });
    });

    return results;
  }

  static validateResults(results) {
    let loading = false;
    let error = false;

    if (results.length === 0) {
      error = true;
    }

    return { loading, error };
  }
}
