import axios from 'axios';

const api = axios.create({
  
  baseURL: process.env.VUE_APP_API_URL,
  // baseURL: 'https://api-v4.fuelguide.be/api',
  // baseURL: 'http://localhost:3000/api',
  headers: {
    'Content-Type': 'application/json',
  },
});

export default api;